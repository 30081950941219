// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aurora-tv-js": () => import("./../../../src/pages/aurora-tv.js" /* webpackChunkName: "component---src-pages-aurora-tv-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-help-center-js": () => import("./../../../src/pages/help-center.js" /* webpackChunkName: "component---src-pages-help-center-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacija-za-covid-19-js": () => import("./../../../src/pages/informacija-za-COVID-19.js" /* webpackChunkName: "component---src-pages-informacija-za-covid-19-js" */),
  "component---src-pages-internet-zashtita-js": () => import("./../../../src/pages/internet-zashtita.js" /* webpackChunkName: "component---src-pages-internet-zashtita-js" */),
  "component---src-pages-kakvo-e-gpon-js": () => import("./../../../src/pages/kakvo-e-gpon.js" /* webpackChunkName: "component---src-pages-kakvo-e-gpon-js" */),
  "component---src-pages-konfigurator-js": () => import("./../../../src/pages/konfigurator.js" /* webpackChunkName: "component---src-pages-konfigurator-js" */),
  "component---src-pages-landing-pages-koledata-e-vyzmojna-js": () => import("./../../../src/pages/landing-pages/koledata-e-vyzmojna.js" /* webpackChunkName: "component---src-pages-landing-pages-koledata-e-vyzmojna-js" */),
  "component---src-pages-landing-pages-koledata-vyzmojna-js": () => import("./../../../src/pages/landing-pages/koledata-vyzmojna.js" /* webpackChunkName: "component---src-pages-landing-pages-koledata-vyzmojna-js" */),
  "component---src-pages-landing-pages-smyatai-lesno-s-nas-js": () => import("./../../../src/pages/landing-pages/smyatai-lesno-s-nas.js" /* webpackChunkName: "component---src-pages-landing-pages-smyatai-lesno-s-nas-js" */),
  "component---src-pages-magazini-js": () => import("./../../../src/pages/magazini.js" /* webpackChunkName: "component---src-pages-magazini-js" */),
  "component---src-pages-netsurf-assistant-js": () => import("./../../../src/pages/netsurf-assistant.js" /* webpackChunkName: "component---src-pages-netsurf-assistant-js" */),
  "component---src-pages-netsurf-fixy-js": () => import("./../../../src/pages/netsurf-fixy.js" /* webpackChunkName: "component---src-pages-netsurf-fixy-js" */),
  "component---src-pages-netsurf-play-js": () => import("./../../../src/pages/netsurf-play.js" /* webpackChunkName: "component---src-pages-netsurf-play-js" */),
  "component---src-pages-pokritie-js": () => import("./../../../src/pages/pokritie.js" /* webpackChunkName: "component---src-pages-pokritie-js" */),
  "component---src-pages-promocii-js": () => import("./../../../src/pages/promocii.js" /* webpackChunkName: "component---src-pages-promocii-js" */),
  "component---src-pages-svoboda-js": () => import("./../../../src/pages/svoboda.js" /* webpackChunkName: "component---src-pages-svoboda-js" */),
  "component---src-pages-za-biznesa-js": () => import("./../../../src/pages/za-biznesa.js" /* webpackChunkName: "component---src-pages-za-biznesa-js" */),
  "component---src-pages-za-doma-bezsrochni-js": () => import("./../../../src/pages/za-doma-bezsrochni.js" /* webpackChunkName: "component---src-pages-za-doma-bezsrochni-js" */),
  "component---src-pages-za-doma-js": () => import("./../../../src/pages/za-doma.js" /* webpackChunkName: "component---src-pages-za-doma-js" */),
  "component---src-pages-zashto-netsurf-js": () => import("./../../../src/pages/zashto-netsurf.js" /* webpackChunkName: "component---src-pages-zashto-netsurf-js" */),
  "component---src-templates-help-single-js": () => import("./../../../src/templates/help-single.js" /* webpackChunkName: "component---src-templates-help-single-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-promo-single-js": () => import("./../../../src/templates/promo-single.js" /* webpackChunkName: "component---src-templates-promo-single-js" */)
}

